import { useState, useEffect } from 'react';
import axios from 'axios';

import Img1 from '../assets/images/Careers/img1.png';
import Img2 from '../assets/images/Careers/img2.png';
import Img3 from '../assets/images/Careers/img3.png';
import Img4 from '../assets/images/Careers/img4.png';

import CardsGrid from '../components/CardsGrid';

import Animation_Diligent from '../assets/animation_diligent.webm';
import Wrapper from '../layout/Wrapper';
import PageTitle from '../components/shared/PageTitle';
import HighlighedText from '../components/shared/HighlighedText';
import CardValues from '../components/shared/CardValues';
import CardLife from '../components/shared/CardLife';
import ActionCard from '../components/shared/ActionCard';
import AboutUsSlider from '../components/shared/AboutUsSlider';
import CardCareers2 from '../components/CareerCardsTemplates/CardCareers2';
import OrbitOnScroll from '../components/shared/graphics/OrbitOnScroll';
import PageLayout from '../layout/PageLayout';
import TimelineCard from '../components/TimelineCard';
//import useAnalytics from '../hooks/useAnalytics';
import VideoComponent from '../components/shared/VideoComponent';
import useAnalytics from '../hooks/useAnalytics';
import useDataApi from '../hooks/useDataApi';
import { strapiApiBuilder } from '../utils/strapiApiBuilder';
import ReactHelmet from './../components/shared/ReactHelmet';
import Paragraph from '../components/blog-micro-components/Paragraph';
import { ReactComponent as Tick } from '../assets/icons/Tick.svg';
import Quote from '../components/blog-micro-components/Quote';
import H3 from '../components/blog-micro-components/H3';
import ActionCard_Array from '../components/shared/ActionCard_Array';

import { ReactComponent as PurpleHex } from '../assets/graphics/Culture/PurpleHex.svg';
import { ReactComponent as BlueHex } from '../assets/graphics/Culture/BlueHex.svg';
import { ReactComponent as YellowTriangle } from '../assets/graphics/Culture/TriangleYellow.svg';
import H2 from '../components/blog-micro-components/H2';

const strapiPopulate = [
  'Heading',
  'HeadingParagraph',
  'ParagraphArray',
  'Cards',
  'Cards.img',
  'SecondHeading',
  'SecondHeadingParagraph',
  'Stats',
  'Quote',
  'WorkTogether',
  'WorkTogether.paragraphs',
  'SEO',
  'SEO.metaSocial',
  'SEO.metaImage',
  'SEO.metaSocial.image',
];

const graphicArray = [
  <PurpleHex key={0} className="z-0 scale-75 md:scale-1" />,
  <YellowTriangle key={1} className="z-0 scale-75 md:scale-1" />,
  <BlueHex key={0} className="z-0 scale-75 md:scale-1" />,
];

export default function Culture({ forwardedRef }) {
  const [graphics, setGraphics] = useState([]);

  const api_url = process.env.REACT_APP_API_URL;

  const strapi = strapiApiBuilder('culture-page', strapiPopulate, '');

  const [{ data, isLoading, isError }, doFetch] = useDataApi(strapi);

  useAnalytics('Culture');

  useEffect(() => {
    document.title = 'Culture';
  }, []);

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }

  return (
    <PageLayout>
      {data && data.SEO && <ReactHelmet seo={data.SEO} />}
      <div className="bg-white dark:bg-dg-primary-1700 w-full pt-32p md:pt-90p overflow-hidden">
        {/* Our Philosophy */}
        {data && data.Heading && data.HeadingParagraph && (
          <Wrapper padding={' py-90p'}>
            <PageTitle
              left
              heading={data?.Heading?.title}
              subheading={data?.Heading?.subtitle}
            />
            <Paragraph data={data.HeadingParagraph.field} />
          </Wrapper>
        )}
        {/* Ticks */}
        {data && data.ParagraphArray && data.ParagraphArray.length > 0 && (
          <Wrapper padding={' md:py-32p'}>
            <div className="md:px-90p flex flex-col md:grid md:grid-cols-2 gap-8p">
              {data.ParagraphArray.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-row gap-2 justify-start items-center"
                >
                  <Tick />
                  <h4 className="capitalize font-semibold text-[24px] md:text-[30px] leading-[36px] md:leading-[45px] text-transparent bg-clip-text bg-gradient-to-r from-[#90278F] to-[#8468BF]">{item.field}</h4>
                </div>
              ))}
            </div>
          </Wrapper>
        )}

        {/* Cards */}
        {data && data.Cards && (
          <Wrapper padding={' py-90p'}>
            <div id="values" className="flex flex-col items-center justify-center mt-16">
              {data.Cards &&
                data.Cards.length > 0 &&
                data.Cards.map((item, i) => (
                  <div
                    key={i}
                    className={
                      'my-8 flex flex-col gap-[32px] md:gap-0 justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0' +
                      (i % 2 ? ' md:flex-row' : ' md:flex-row-reverse')
                    }
                  >
                    <div className="w-full md:w-1/2 md:pr-16">
                      <div>
                        <h3 className="h3-heading">{item.title}</h3>
                        <p className="text-sm text-dark-gray dark:text-white mt-4">
                          {item.paragraph}
                        </p>
                      </div>
                    </div>
                    <img
                      src={item.img.data.attributes.url}
                      alt={item.img.data.attributes.alternativeText}
                      className="text-center w-full md:w-1/2"
                    />
                  </div>
                ))}
            </div>
          </Wrapper>
        )}

        {/* Second Title */}
        {data && data.Heading && data.HeadingParagraph && (
          <Wrapper padding={' py-[32px] md:py-90p'}>
            <PageTitle
              left
              heading={data?.SecondHeading?.title}
              subheading={data?.SecondHeading?.subtitle}
            />
            <Paragraph data={data.SecondHeadingParagraph.field} />
          </Wrapper>
        )}

        {/* Quote */}
        {data && data.SecondHeading && data.SecondHeadingParagraph && (
          <Wrapper padding={' py-[16px] md:py-[64px]'}>
            <Quote color larger data={data.Quote.field} />
          </Wrapper>
        )}

        {/* Stats */}
        {data && data.Stats && data.Stats.length > 0 && (
          <Wrapper gradient padding={' py-[64px]'}>
            <div className="flex flex-col gap-[64px] px-[16px] md:px-[64px] py-[32px]">
              {data.Stats.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-row gap-[48px] items-center text-white"
                >
                  <div className="relative">
                    <div className="absolute -left-[36px] -top-[24px]  md:-left-8 md:-top-2 z-0">
                      {graphicArray.length > index
                        ? graphicArray[index]
                        : graphicArray[index - 3]}
                    </div>
                    <H2
                      data={item.value}
                      styleProps={
                        ' relative text-white capitalize text-[32px] leading-[38px] md:text-[64px] md:leading-[72px] z-[1000]'
                      }
                    />
                  </div>
                  <H2 data={item.title} styleProps={' text-white capitalize text-[18px] md:text-[32px]'} />
                </div>
              ))}
            </div>
          </Wrapper>
        )}

        {/* Action Card */}
        {data && data.WorkTogether && (
          <Wrapper>
            <div className="my-90p">
              <ActionCard_Array
                title={data.WorkTogether.title}
                text={data.WorkTogether.paragraphs}
                btn1={data.WorkTogether.ButtonPrimary}
                btn2={data.WorkTogether.ButtonSecondary}
                link1={'/contact'}
                link2={'/about'}
              />
            </div>
          </Wrapper>
        )}
      </div>
    </PageLayout>
  );
}
