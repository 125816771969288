//import FormSwitch from '../components/shared/FormSwitch';
import PageHeading from './../components/shared/PageHeading';
import Contact from '../components/shared/Contact';
import BlogSection from '../components/BlogSection';
import WhyUsCard from '../components/WhyUsCard';
import HiringWidget from '../components/HiringWidget';

import Animation_Diligent from '../assets/animation_diligent.webm';

import '../styles/buttons.css';
import '../styles/cards.css';

//import bg_home from '../assets/logos/bg_home.png';
//import post_1 from '../assets/logos/post_1.png';

import { motion } from 'framer-motion';
import { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react';
import axios from 'axios';
import OrbitOnScroll from '../components/shared/graphics/OrbitOnScroll';
import LandingSVG from '../components/shared/graphics/LandingSVG';
import ServicesHome from '../components/ServicesHome';
import Testimonials from '../components/Testimonials';
import LandingSVGv2 from '../components/shared/graphics/LandingSVG-v2';
import Landing from '../components/Landing';
import WhySection from '../components/WhySection';
import ProcessSection from '../components/ProcessSection';
import TechStack from '../components/TechStack';
import PortfolioSection from '../components/PortfolioSection';
import PageLayout from '../layout/PageLayout';
import MapDilig from '../components/Map';
import useDataApi from '../hooks/useDataApi';
import useAnalytics from '../hooks/useAnalytics';
import ReactHelmet from '../components/shared/ReactHelmet';
import { UIContext } from './../context/index';
import { strapiApiBuilder } from './../utils/strapiApiBuilder';
import TestimonialsDataLayer from '../api/TestimonialsDataLayer';
import PortfolioDataLayer from '../api/PortfolioDataLayer';

const api_url = process.env.REACT_APP_API_URL;

const strapiPopulate = [
  'Heading',
  'Heading.subtitle',
  'Heading.title',
  'WhyUsHeading',
  'Cards',
  'Cards.Card1',
  'Cards.Card1.Image',
  'Cards.Card2',
  'Cards.Card2.Image',
  'Cards.Card3',
  'Cards.Card3.Image',
  'HeroNumbers',
  'HeroNumbers.number',
  'ProcessHeading',
  'ProcessImage',
  'TechStackHeading',
  'ProcessMobileImg',
  'ProcessMobileImg.Image',
  'CaseStudies',
  'Map',
  'SEO',
  'SEO.metaSocial',
  'SEO.metaImage',
  'SEO.metaSocial.image',
  'BusinessInquiry',
  'BusinessInquiry.image',
  'ApplyPosition',
  'ApplyPosition.image',
];

export default function Home({ forwardedRef }) {

  const strapi = strapiApiBuilder('w-home-page', strapiPopulate, '');

  const [{ data, isLoading, isError }, doFetch] = useDataApi(strapi);


  useAnalytics('Home');

  useEffect(() => {
    document.title = 'Diligent Software';
  }, []);


  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 overflow-hidden dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  } else {
    return (
      <PageLayout>
        {(data && data.SEO) ? <ReactHelmet seo={data.SEO} /> : null}
        <div className="bg-white dark:bg-dg-primary-1700 w-full pt-32 overflow-hidden">

          {/* Landing Section */}
          {data ? (
            <Landing
              heading={data.Heading}
              numbers={data.HeroNumbers.number}
              paragraph={data.paragraph}
              button={data.button}
            />
          ) : null}

          {/* Why Us Section */}
          {data ? (
            <WhySection
              heading={data.WhyUsHeading}
              p1={data.WhyUsParagraph1}
              p2={data.WhyUsParagraph2}
              cards={data.Cards}
            />
          ) : null}

          {/* Our Process Section */}
          {data ? (
            <ProcessSection
              heading={data.ProcessHeading}
              btn={data.ProcessCTA}
              image={data.ProcessImage}
              mobileImages={data.ProcessMobileImg.Image}
            />
          ) : null}

          {/* Our Process Section */}
          {data ? (
            <TechStack heading={data.TechStackHeading} btn={data.TechStackCTA} />
          ) : null}

          {/* Testimonials Section*/}
          {data ? <TestimonialsDataLayer /> : null}

          {/* Portfolio Section*/}

          {data ? (
            <PortfolioSection
              heading={data.CaseStudies}
              cta={data.CtaForCaseStudiesCards}
            />
          ) : null}


          {/* Contact Section */}
          {data ? (
            <section id="contact" className="" ref={forwardedRef}>
              <Contact
                defaultIndex={true}
                position={''}
            job={data.ApplyPosition}
            business={data.BusinessInquiry}
              />            
            </section>
          ) : null}

          {data ? <MapDilig heading={data.Map} /> : null}
        </div>
      </PageLayout>
    );
  }
}
