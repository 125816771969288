import PropTypes, { func } from 'prop-types';

import axios from 'axios';

import OrbitOnClick from './graphics/OrbitOnClick';
import Animation_Diligent from '../../assets/animation_diligent.webm';

import { Tab } from '@headlessui/react';
import { useState, useContext, useEffect } from 'react';

import ClientForm from './ClientForm';
import JobForm from './JobForm';
import { ClientFormContext } from '../../context';
import { JobFormContext } from '../../context';
import PageTitle from './PageTitle';
import Wrapper from '../../layout/Wrapper';

import Mailgun from 'mailgun.js';
import FormData from 'form-data';
import { UIContext } from '../../context/UIContextProvider';

const mailgun = new Mailgun(FormData);
const mg = mailgun.client({
  username: 'api',
  key: process.env.REACT_APP_MAILGUN_API_KEY,
  url: 'https://api.eu.mailgun.net',
});

export default function Contact({ activeTab, position, job, business }) {
  const [tab, setTab] = useState(activeTab);
  const [tabTitle, setTabTitle] = useState(business.title);

  function handleContextMenu(event) {
    event.preventDefault();
  }

  function handleTabClick(event) {
    //if (event.button !== 0)
    event.preventDefault();
  }
  const { uiContext, setUiContext } = useContext(UIContext);
  const clientContext = useContext(ClientFormContext);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  function handleTab(tabIndex) {
    if (tabIndex == 0) {
      setTab(true);
      setTabTitle(business.title);
    } else {
      setTab(false);
      setTabTitle(job.title);
    }
  }

  const initialClientValues = clientContext ?? {
    tag: '',
    subject: '',
    firstName: '',
    lastName: '',
    email: '',
    description: '',
  };

  const api_url = process.env.REACT_APP_API_URL;

  const [clientForm, setClientForm] = useState(initialClientValues);

  const [cntCareers, setCntCareers] = useState('');
  const [isLoaded, setIsLoaded] = useState('');
  useEffect(async () => {
    var vid = document.getElementById('animation');
    vid.playbackRate = 2;
    axios
      .get(
        `${api_url}/api/w-home-page?populate[0]=BusinessInquiry.image&populate[1]=ApplyPosition.image`,
      )
      .then(res => {
        setCntCareers(res.data.data.attributes);
        setIsLoaded(true);
      })
      .catch(err => {
        console.log(err);
        setIsLoaded(false);
      });
  }, []);

  if (!isLoaded) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }

  return (
    <Wrapper bg>
      <div className="absolute top-40 md:top-8 right-11 md:right-12 z-10">
        <OrbitOnClick tab={tab} />
      </div>
      <div className="py-16 relative">
        <div className="flex justify-end flex-col">
          <Tab.Group defaultIndex={tab ? 0 : 1} onChange={index => handleTab(index)}>
            <div
              className="flex flex-col md:flex-row items-start md:items-baseline md:justify-between"
              onMouseDown={handleTabClick}
            >
              {tab ? (
                <PageTitle left heading={cntCareers.BusinessInquiry.title} subheading={cntCareers.BusinessInquiry.subtitle} />
              ) : (
                <PageTitle left heading={cntCareers.ApplyPosition.title} subheading={cntCareers.ApplyPosition.subtitle} />
              )}

              <Tab.List className="flex flex-row items-center md:flex-col lg:flex-row h-fit w-fit max-w-max md:ml-auto z-20 p-1 min-h-12 space-x-1 bg-dg-primary-400 rounded-xl my-4 align-middle">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      'h-fit w-fit py-2 px-4 text-sm leading-5 font-medium text-dg-primary-900 rounded-lg',
                      'focus:outline-none ',
                      selected
                        ? 'bg-white shadow'
                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-white',
                    )
                  }
                >
                  Business Inquiry
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      'h-fit w-fit py-2 px-4 text-sm leading-5 font-medium text-dg-primary-900 rounded-lg',
                      'focus:outline-none',
                      selected
                        ? 'bg-white shadow'
                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-white',
                    )
                  }
                >
                  Apply for a position
                </Tab>
              </Tab.List>
            </div>

            <Tab.Panels className="w-full mt-2 mx-auto">
              <Tab.Panel className={classNames('py-3', 'outline-none')}>
                <ClientFormContext.Provider value={{ clientForm, setClientForm }}>
                  <ClientForm mg={mg} cta={business.cta} img={business.image} />
                </ClientFormContext.Provider>
              </Tab.Panel>
              <Tab.Panel className={classNames('py-3', 'outline-none')}>
                <JobForm cta={job.cta} img={job.image} cntCareers={cntCareers} mg={mg} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </Wrapper>
  );
}

Contact.propTypes = {
  setTab: PropTypes.func,
  tabTitle: PropTypes.string,
  setTabTitle: PropTypes.func,
  defaultIndex: PropTypes.number,
  defaultPositionSelection: PropTypes.string,
};
