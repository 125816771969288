import React, {Fragment, useEffect} from "react";

const PageLayout = ({children}) => {

    // useEffect(() => {
    //     window.scrollTo(0, 0)
    //   }, [])
    
    return (
        <Fragment>{children}</Fragment>
    );
}

export default PageLayout;