import React, { Fragment, useEffect } from 'react';
import useScreenDimensions from '../hooks/useScreenDimensions';
import Wrapper from '../layout/Wrapper';
import FMCarousel from './shared/FMCarousel';
import PageTitle from './shared/PageTitle';
import TestimonialCard from './shared/TestimonialCard';

const api_url = process.env.REACT_APP_API_URL;

const _data = {
  heading: 'What Our Clients Said',
  subheading: 'Testimonials',
};

export default function Testimonials({ data, noTitle, wideArray }) {
  const screenSize = useScreenDimensions();

  return (
    <Wrapper padding={' py-90p'}>
      <div className="flex flex-col gap-32p md:gap-90p w-full">
        {!noTitle && (
          <div className="w-full">
            <PageTitle heading={_data.heading} subheading={_data.subheading} left />
          </div>
        )}

        <div className="relative w-full h-[500px] overflow-hidden">
          {screenSize.width > 1000 ? (
            <FMCarousel>
              {wideArray &&
                wideArray.length > 0 &&
                wideArray.map((item, index) => (
                  <div className="flex" key={index}>
                    {typeof item[0] !== 'undefined' && (
                      <Fragment>
                        <TestimonialCard
                          name={item[0].attributes.Name}
                          role={item[0].attributes.Role}
                          company={item[0].attributes.Company}
                          clientImg={item[0].attributes.Image.data?.attributes.url}
                          paragraph={item[0].attributes.Quote}
                        />
                      </Fragment>
                    )}

                    {typeof item[1] !== 'undefined' && (
                      <Fragment>
                        <TestimonialCard
                          name={item[1].attributes.Name}
                          role={item[1].attributes.Role}
                          company={item[1].attributes.Company}
                          clientImg={item[1].attributes.Image.data?.attributes.url}
                          paragraph={item[1].attributes.Quote}
                        />
                      </Fragment>
                    )}
                  </div>
                ))}
            </FMCarousel>
          ) : (
            <FMCarousel>
              {data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <Fragment key={index}>
                    <TestimonialCard
                      name={item.attributes.Name}
                      role={item.attributes.Role}
                      company={item.attributes.Company}
                      clientImg={api_url + item.attributes.Image.data?.attributes.url}
                      paragraph={item.attributes.Quote}
                    />
                  </Fragment>
                ))}
            </FMCarousel>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
