import React from 'react';

function MailchimpForm() {
  return (
    <div id="mc_embed_shell">
      <link
        href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
        rel="stylesheet"
        type="text/css"
      />
      <div id="mc_embed_signup">
        <form
          action="https://dilig.us18.list-manage.com/subscribe/post?u=4bd507e0bc2f58fc19f284648&amp;id=8a749a060b&amp;f_id=00e91fe7f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_self"
          noValidate=""
        >
          <div id="mc_embed_signup_scroll">
            <div className="indicates-required">
              <span className="asterisk">*</span> indicates required
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">
                Email Address <span className="asterisk"></span>
              </label>
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                required=""
                value=""
              />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-FNAME">
                First Name <span className="asterisk"></span>
              </label>
              <input
                type="text"
                name="FNAME"
                className="required text"
                id="mce-FNAME"
                required=""
                value=""
              />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-LNAME">
                Last Name <span className="asterisk">*</span>
              </label>
              <input
                type="text"
                name="LNAME"
                className="required text"
                id="mce-LNAME"
                required=""
                value=""
              />
            </div>
            <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
              <div
                className="response"
                id="mce-success-response"
                style={{display: 'none'}}
              ></div>
            </div>
            <div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
              <input
                type="text"
                name="b_4bd507e0bc2f58fc19f284648_8a749a060b"
                tabIndex="-1"
                value=""
              />
            </div>
            <div className="clear">
              <input
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
                value="Subscribe"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MailchimpForm;
