import React, { Children, Fragment, useEffect } from 'react';
import fileDownload from 'js-file-download';
import CustomLink from '../components/root/CustomLink';
import ActionCard from '../components/shared/ActionCard';

import useDataApi from '../hooks/useDataApi';
import Animation_Diligent from '../assets/animation_diligent.webm';
import Wrapper from '../layout/Wrapper';

import PageLayout from '../layout/PageLayout';
import PageTitleOneFont from '../components/shared/PageTitleOneFont';
import GradientWrapper from '../components/shared/GradientWrapper';

import '../App.css';
import useAnalytics from './../hooks/useAnalytics';
import { strapiApiBuilder } from './../utils/strapiApiBuilder';
import ReactHelmet from './../components/shared/ReactHelmet';
import WorkClientForm from './../components/WorkClientForm';

import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { useState } from 'react';

const api_url = process.env.REACT_APP_API_URL;
const mailchimp_url =
  'https://dilig.us18.list-manage.com/subscribe/post?u=4bd507e0bc2f58fc19f284648&amp;id=09da427d96&amp;f_id=00b927e7f0';

const download = {
  downloadFilePath: `${process.env.PUBLIC_URL}/DiligentCompanyOverview.pdf`,
  downloadFileName: 'Diligent Company Overview.pdf',
};

const strapiPopulate = [
  'Heading',
  'Heading.subtitle',
  'Heading.title',
  'Problems',
  'Highlighted',
  'Highlighted.title',
  'Highlighted.paragraph',
  'WhyWork',
  'WhyWork.img',
  'Stats',
  'SucessParagraph',
  'Specs',
  'Download',
  'OfficeImg',
  'WorkTogether',
  'SEO',
  'SEO.metaSocial',
  'SEO.metaImage',
  'SEO.metaSocial.image',
];

const NumberIcon = ({ number }) => {
  return (
    <div className="bg-baby-blue rounded-[6px] h-[48px] w-[48px] flex justify-center items-center text-center font-semibold text-dg-primary-900 text-n-h3-heading-mobile md:n-h3-heading">
      <h4>{number}.</h4>
    </div>
  );
};

const WorkWithUs = () => {
  const [formSuccess, setFormSuccess] = useState(false);

  const strapi = strapiApiBuilder('work-with-us-page', strapiPopulate, '');

  const [{ data, isLoading, isError }, doFetch] = useDataApi(strapi);

  useAnalytics('Work With Us');

  useEffect(() => {
    document.title = 'Work With Us';
  }, []);

  function downloadFile(filePath) {
    // var link = document.createElement('a');
    // link.href = filePath;
    // link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    // link.click();
    window.open(filePath.substr(filePath.lastIndexOf('/') + 1), "_blank");
  }

  useEffect(() => {
    formSuccess && downloadFile(download.downloadFilePath);
  }, [formSuccess]);

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 overflow-hidden dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  } else {
    return (
      <PageLayout>
        {data && data.SEO && <ReactHelmet seo={data.SEO} />}
        <div className="mt-[80px] md:mt-[180px]">
          <Wrapper padding={' py-[48px]'}>
            {data && data.Heading ? (
              <Fragment>
                <PageTitleOneFont
                  heading={data.Heading.title}
                  subheading={data.Heading.subtitle}
                  left
                />
                <p className="mt-[16px] mr-0 md:mr-[400px] n-paragraph">
                  {data.HeadingParagraph}
                </p>
              </Fragment>
            ) : null}
          </Wrapper>
          <div className="mx-auto md:mx-2/5 pb-90p">
            <Wrapper padding={' md:py-[48px]'}>
              {data ? (
                <h2 className="n-h3-heading md:text-center">{data.ProblemsTitle}</h2>
              ) : null}
            </Wrapper>

            <Wrapper padding={' py-[48px]'}>
              {data ? (
                <div className="flex flex-col gap-90p max-w-[860px] mx-auto">
                  {data.Problems.map((item, index) => (
                    <div key={index} className="flex flex-col md:flex-row gap-[32px]">
                      <div>
                        <NumberIcon number={index + 1} />
                      </div>
                      <div className="flex flex-col gap-[4px]">
                        <h6 className="n-paragraph-title">{item.title}</h6>
                        <p className="n-paragraph">{item.paragraph}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </Wrapper>
          </div>
          {data ? (
            <GradientWrapper padding={'py-32p md:py-90p'}>
              <h6 className="n-h3-heading text-white">{data.Highlighted.title}</h6>
              <p className="n-paragraph text-white md:max-w-[800px] mx-0 md:mx-auto">
                {data.Highlighted.paragraph}
              </p>
            </GradientWrapper>
          ) : null}
          {data ? (
            <Wrapper padding={' py-[32px] md:py-[200px]'}>
              <div className="flex flex-col gap-[64px] md:gap-[120px]">
                {data.WhyWork.map((item, index) => (
                  <div
                    key={index}
                    className={
                      (index % 2
                        ? 'flex flex-col md:flex-row-reverse '
                        : 'flex flex-col md:flex-row ') +
                      'w-full justify-between items-center gap-[24px] md:gap-0'
                    }
                  >
                    <div>
                      <img src={item.img.data.attributes.url}></img>
                    </div>
                    <div className="flex flex-col gap-[8px] md:gap-[16px] max-w-[520px]">
                      <h6 className="n-h3-heading text-dark-gray">{item.title}</h6>
                      <p className="n-paragraph">{item.paragraph}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Wrapper>
          ) : null}
          {data ? (
            <GradientWrapper padding={'py-32p md:py-90p'}>
              <div className="max-w-custom flex flex-col md:flex-row md:justify-between gap-[64px] md:gap-0">
                {data.Stats.map((item, index) => (
                  <div key={index} className="flex flex-col gap-[8px] text-center">
                    <h6 className="n-heading text-white">{item.value}</h6>
                    <p className="n-paragraph-title text-white">{item.title}</p>
                  </div>
                ))}
              </div>
            </GradientWrapper>
          ) : null}
          <Wrapper padding={' py-[48px] md:py-[200px]'}>
            {data ? (
              <div className="flex flex-col gap-[16px]">
                <h6 className="n-h3-heading text-dark-gray">{data.SuccessTitle}</h6>
                {data.SucessParagraph.map((item, index) => (
                  <p key={index} className="n-paragraph">
                    {item.ParagraphElement}
                  </p>
                ))}
              </div>
            ) : null}
          </Wrapper>
          <div className="mx-auto md:mx-2/5 pb-90p">
            <Wrapper padding={' md:py-[48px]'}>
              {data ? (
                <h6 className="n-h3-heading md:text-center text-dark-gray">
                  {data.SpecTitle}
                </h6>
              ) : null}
            </Wrapper>

            <Wrapper padding={' py-[48px]'}>
              {data ? (
                <div className="flex flex-col sm:flex-row gap-[48px] mx-auto justify-center z-10 opacity-100 font-semibold">
                  <div className="flex flex-col gap-32p sm:mb-8">
                    <div className="bg-white py-32p px-[24px] rounded-[8px] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                      {data.Specs[0].text}
                    </div>
                    <div className="bg-white py-32p px-[24px] rounded-[8px] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                      {data.Specs[1].text}
                    </div>
                    <div className="bg-white py-32p px-[24px] rounded-[8px] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                      {data.Specs[2].text}
                    </div>
                  </div>
                  <div className="flex flex-col gap-32p sm:mt-8">
                    <div className="bg-white py-32p px-[24px] rounded-[8px] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                      {data.Specs[3].text}
                    </div>
                    <div className="bg-white py-32p px-[24px] rounded-[8px] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                      {data.Specs[4].text}
                    </div>
                    <div className="bg-white py-32p px-[24px] rounded-[8px] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                      {data.Specs[5].text}
                    </div>
                  </div>
                  <div className="flex flex-col gap-32p sm:mb-8">
                    <div className="bg-white py-32p px-[24px] rounded-[8px] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                      {data.Specs[6].text}
                    </div>
                    <div className="bg-white py-32p px-[24px] rounded-[8px] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                      {data.Specs[7].text}
                    </div>
                    <div className="bg-white py-32p px-[24px] rounded-[8px] text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                      {data.Specs[8].text}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="flex absolute w-full top-0 z-[-1]">
                <div className="w-[624px] h-[624px] bg-indigo-500 opacity-25 rounded-full mx-auto blur-[100px]"></div>
              </div>
            </Wrapper>
          </div>
          {data ? (
            <div className="md:mb-[200px]">
              <div className="w-full relative mt-12 z-[-1]">
                <div className="radial-gradient absolute top-0 left-0 right-0 bottom-0"></div>
                <div className="max-w-custom mx-auto">
                  <img
                    src={data.OfficeImg.data.attributes.url}
                    alt={data.OfficeImgAlt}
                  ></img>
                </div>
              </div>
              <MailchimpSubscribe
                url={mailchimp_url}
                render={({ subscribe, status, message }) => (
                  <>
                    <WorkClientForm
                      data={data.Download}
                      download={download}
                      status={status}
                      message={message}
                      onValidated={formData => subscribe(formData)}
                    />
                    {status === 'success' ? setFormSuccess(true) : setFormSuccess(false)}
                  </>
                )}
              />
            </div>
          ) : null}
          {data ? (
            <Wrapper padding={' py-90p'}>
              <ActionCard
                title={data.WorkTogether.title}
                text={data.WorkTogether.paragraph}
                btn2={data.WorkTogether.ButtonSecondary}
                btn1={data.WorkTogether.ButtonPrimary}
                link2={'/portfolio'}
                link1={'/contact'}
              />
            </Wrapper>
          ) : null}
        </div>
      </PageLayout>
    );
  }
};

export default WorkWithUs;
