import React, { useEffect, useState } from 'react';
import PageTitle from './shared/PageTitle';
import { ReactComponent as ProcessSvg } from './../assets/images/Process.svg';
import Wrapper from '../layout/Wrapper';
import { useNavigate } from 'react-router-dom';
import ProcessSlider from './ProcessSlider';
import { useWindowInfo } from '@faceless-ui/window-info';
import useWindowSize from '../hooks/useWindowSize';

const _data = {
  heading: 'How We Do It',
  subheading: 'Our process',
  imgUrl: '',
  btn: 'check it out',
};

const ProcessSection = ({ image, imageAlt, heading, btn, mobileImages }) => {
  const [isMobile, setIsMobile] = useState(false);

  const windowInfo = useWindowSize();

  useEffect(() => {
    //console.log(windowInfo.width);
    if (windowInfo.width < 1000) setIsMobile(true);
    else setIsMobile(false);
  }, [windowInfo]);

  const link = useNavigate();

  return (
    <Wrapper padding={' py-90p'} hideOverflow>
      <div className="w-full">
        {heading ? (
          <PageTitle
            heading={heading.title}
            subheading={heading.subtitle}
            color
            left
          />
        ) : null}
      </div>

      <div className="relative mx-auto my-32p md:my-90p">
        {!isMobile ? (
          <div className="w-full">
            {image ? <img src={image.data.attributes.url} alt={image.data.attributes.alternativeText}></img> : null}
          </div>
        ) : (
          <div className="">
            <ProcessSlider images={mobileImages.data} />
          </div>
        )}
      </div>
      <div className="flex justify-center w-full">
        <button className="btn-secondary" onClick={() => link('/process')}>
          {btn}
        </button>
      </div>
    </Wrapper>
  );
};

export default ProcessSection;
