import React, { useRef } from 'react';
import TagInput from '../TagInput';
import img from '../../assets/images/Inqueries.png';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { ClientFormContext } from '../../context';
import { useContext, useState } from 'react';
import * as emailjs from 'emailjs-com';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga4';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { useEffect } from 'react';

const api_url = process.env.REACT_APP_API_URL;

export default function ClientForm({ mg, img, cta }) {
  //search context for prevous entry TODO
  const [btnText, setBtnText] = useState('');
  const { clientForm, setClientForm } = useContext(ClientFormContext);
  const [sucMsg, setSucMsg] = useState(false);
  const captchaRef = useRef(null);
  const [msgText, setMsgText] = useState('');
  const changeFormHandler = event => {
    const { name, value } = event.target;
    setClientForm({
      ...clientForm,
      [name]: value,
    });
  };

  useEffect(() => {
    setBtnText(cta);
  },[cta]);




  const validationSchema = Yup.object({
    subject: Yup.string()
      .min(2, 'Subject too short')
      .max(50, 'Subject too long')
      .required('Subject is Required'),
    email: Yup.string().email('Invalid email format').required('Email is Required'),
    firstName: Yup.string()
      .min(2, 'First name too short')
      .max(50, 'First name too long')
      .required('First Name is Required'),
    lastName: Yup.string()
      .min(2, 'Last name too short')
      .max(50, 'Last name too long')
      .required('Last Name is Required'),
    description: Yup.string()
      .trim()
      .min(2, 'Description too short')
      .required('Description is Required'),
  });

  return (
    <div className="mt-10 sm:mt-0 mx-auto">
      <div className="md:grid md:grid-cols-2 md:gap-6">
        <motion.div
          className="mt-5 md:mt-0 md:col-span-1"
          initial={{ x: -60, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -60, opacity: 0 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
        >
          <Formik
            initialValues={clientForm}
            validationSchema={validationSchema}
            onSubmit={async values => {
              const data = {
                Tag: values.tag,
                Subject: values.subject,
                Email: values.email,
                Firstname: values.firstName,
                Lastname: values.lastName,
                Description: values.description,
              };
              const token = captchaRef.current.getValue();
              captchaRef.current.reset();

              if (token.length === 0) {
                setSucMsg(true);
                setMsgText('Please fill reCAPTCHA and try again. Thank you!');
              } else {
                await axios
                  .post(`${process.env.REACT_APP_CAPTCHA_API}/verify-token`, { token })
                  .then(res => {
                    setSucMsg(true);
                    if (res.data.data.success) {
                      setMsgText('Submission Succesful! Thank you!');
                      mg.messages.create('dilig.net', {
                        from: `${values.firstName} ${values.lastName} <${values.email}>`,
                        to: ['hr@dilig.net'],
                        subject: `${values.subject}`,
                        text: `${values.description}`,
                        html: `<p>${values.description}</p>`,
                      });
                    } else setMsgText('Please fill reCAPTCHA and try again. Thank you!');
                  })
                  .catch(error => {
                    //console.log(error);
                  });
              }
              ReactGA.event('contact', {
                category: 'Contact',
                action: 'Business Inquiry',
              });
            }}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <div className=" sm:rounded-md">
                  <div className="py-2 sm:py-6">
                    <div className="">
                      <div className="col-span-1 sm:col-span-1">
                        <div className="py-1">
                          <TagInput changeFormHandler={changeFormHandler} props={props} />
                        </div>
                        <div className="py-1">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                          >
                            First name
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            onBlur={changeFormHandler}
                            value={props.values.firstName}
                            onChange={props.handleChange}
                            autoComplete="given-name"
                            className="mt-1 focus:ring-dg-primary-600 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                          />
                          <div className="h-4">
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="text-sm text-right text-red-600"
                            />
                          </div>
                        </div>
                        <div className="py-1">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                          >
                            Last name
                          </label>
                          <input
                            onBlur={changeFormHandler}
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={props.values.lastName}
                            onChange={props.handleChange}
                            autoComplete="family-name"
                            className="mt-1 focus:ring-dg-primary-900 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                          />
                          <div className="h-4">
                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="text-sm text-right text-red-600"
                            />
                          </div>
                        </div>
                        <div className="py-1">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                          >
                            Email
                          </label>
                          <input
                            onBlur={changeFormHandler}
                            type="email"
                            name="email"
                            id="email"
                            value={props.values.email}
                            onChange={props.handleChange}
                            autoComplete="email"
                            className="mt-1 focus:ring-dg-primary-900 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white dark:autofill:text-white dark:autofill:bg-dg-primary-1500 block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                          />
                          <div className="h-4">
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-sm text-right text-red-600"
                            />
                          </div>
                        </div>
                        {/* <div className="form-check py-2">
                                  <input
                                    className="form-check-input appearance-none h-6 w-6 border text-dg-primary-900 border-dg-primary-600 rounded-sm bg-white checked:dg-primary-900 checked:border-dg-primary-900 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer focus:ring-dg-primary-900"
                                    type="checkbox"
                                    value=""
                                    id="meeting"
                                  />
                                  <label
                                    className="ml-3 form-check-label inline-block text-gray-800"
                                    htmlFor="meeting"
                                  >
                                    Schedule a meeting right away
                                  </label>
                                </div> */}

                        <div className="py-1">
                          <label
                            htmlFor="Description"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                          >
                            Description
                          </label>
                          <textarea
                            onBlur={changeFormHandler}
                            type="text"
                            placeholder="Describe your question or proposition..."
                            className="resize-y min-h-12 h-32 rounded-md mt-1 text-base focus:ring-dg-primary-900 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white sm:text-sm border-dg-primary-600 block w-full shadow-sm transition duration-200"
                            name="description"
                            id="description"
                            value={props.values.description}
                            onChange={props.handleChange}
                          ></textarea>
                          <div className="h-4">
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="text-sm text-right text-red-600"
                            />
                          </div>
                        </div>
                        <div className="items-center justify-end flex">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            ref={captchaRef}
                          />
                        </div>
                        <div className=" py-3 text-right">
                          <button
                            type="submit"
                            className="btn btn_primary transition-all inline-flex justify-center py-4 px-14 border border-transparent shadow-md text-sm font-semibold rounded-xl text-white bg-dg-primary-600 hover:bg-dg-primary-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dg-primary-600"
                          >
                            {btnText}
                          </button>
                        </div>
                        {sucMsg && (
                          <div className={'text-sm text-right text-dg-primary-900'}>
                            {msgText}
                          </div>
                        )}
                      </div>

                      <div className="col-span-1 sm:col-span-1 lg:col-span-1"></div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </motion.div>
        <motion.div
          className="mt-5 md:mt-0 md:col-span-1 flex items-center"
          initial={{ x: 60, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 60, opacity: 0 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
        >
          <img src={img.data.attributes.url} alt={img.data.attributes.alternativeText} />
        </motion.div>
      </div>
    </div>
  );
}
