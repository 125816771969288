import React from 'react';
import PropTypes from 'prop-types';

const api_url = process.env.REACT_APP_API_URL;

const CareerCard = ({ card, setExpanded, setExpandedCard }) => {
  return (
    <div
      onClick={() => {
        setExpandedCard(card);
        setExpanded(true);   
      }}
      className={'card max-w-[360px]'}
    >
      <img
        src={card.icon.data.attributes.url}
        alt={card.icon.data.attributes.alternativeText}
        className={'ml-auto mr-auto block w-[70px] bg-none text-dark-gray rounded-full'}
      />
      <h3 className={'mt-6 font-semibold text-2xl'}>{card.title}</h3>
      <p className={'mt-6 text-sm'}>{card.experience}</p>
    </div>
  );
};

CareerCard.propTypes = {
  card: PropTypes.object,
};

export default CareerCard;
