import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';


import Animation_Diligent from './../assets/animation_diligent.webm';

const api_url = process.env.REACT_APP_API_URL;

export default function BlogSection(props) {
  const [cntBlog, setCntBlog] = useState('');
  const [isLoaded, setIsLoaded] = useState('');


  useEffect(async () => {
    var vid = document.getElementById('animation');
    vid.playbackRate = 2;
    axios
      .get(`${api_url}/api/blogpage?populate[0]=post.image`)
      .then(res => {
        setCntBlog(res.data.data.attributes);
        setIsLoaded(true);
      })
      .catch(err => {
        console.log(err);
        setIsLoaded(false);
      });
  }, []);
  if (!isLoaded) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }

  return (
    <section id="blog_section" className={props.bgColor + ' ' + props.padding}>
      <motion.div
        className="max-w-custom m-auto"
        initial={{ y:60, opacity: 0 }}
        whileInView={{ y:0, opacity: 1}} 
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <section id="heading" className="flex flex-col justify-center px-4">
          <div className="my-8 flex justify-start items-center w-4/5">
            <div className="w-4/5 lg:w-3/5">
              <h6 className="subheading">
                {cntBlog.subheading}
              </h6>
              <h1 className="heading mt-2">
                {cntBlog.heading}
              </h1>
            </div>
          </div>
        </section>
        {/* Blog Posts Section */}
        <section
          id="posts"
          className="flex flex-col items-center justify-center px-4 mb-8"
        >
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full">
            {cntBlog.post.map(post => (
              <div
                className="card w-full lg:w-fit text-left flex flex-col justify-between lg:first:col-span-2"
                key={post.id}
              >
                <div className="mb-12">
                  <div className="w-full">
                    <img
                      src={post.image.data.attributes.formats.thumbnail.url}
                      alt="Post's image"
                      className="max-w-fit max-h-fit"
                    />
                  </div>
                  <h3 className="font-semibold text-2xl mb-2">{post.heading}</h3>
                  <p className="text-sm">{post.paragraph}</p>
                </div>
                <div className="flex flex-row items-center justify-between">
                  <h6 className="text-gray-500 text-xs">{post.date}</h6>
                  <Link
                    to="/post"
                    state={{
                      date: post.date,
                      heading: post.heading,
                      subheading: cntBlog.subheading,
                      content: post.content,
                    }}
                    className="font-semibold text-dg-secondary underline transition-all hover:text-dg-primary-900 hover:transition-all"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>
      </motion.div>
    </section>
  );
}

BlogSection.propTypes = {
  bgColor: PropTypes.string,
  padding: PropTypes.string,
};
