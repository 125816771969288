import propTypes from 'prop-types';

const api_url = process.env.REACT_APP_API_URL;

export default function CaseStudy(props) {
  const study = props.fetch;

  return (
    <div className="flex flex-col items-center justify-center dark:text-white max-w-custom">
      <h2 className="text-3xl text-dg-primary-900 font-medium mt-8 w-full">
        {props.heading}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-36 mt-8">
        {/* Left Side */}
        <div className="">
          <div>
            <h3 className="font-semibold text-xl">{props.goals_heading}</h3>
            <p className="text-sm">{props.goals_paragraph}</p>
          </div>
          <div className="mt-8">
            <h3 className="font-semibold text-xl">{props.challange_heading}</h3>
            <p className="text-sm">{props.challange_paragraph}</p>
          </div>
          <div className="mt-8">
            <h3 className="font-semibold text-xl">{props.solution_heading}</h3>
            <p className="text-sm">{props.solution_paragraph}</p>
          </div>
        </div>
        {/* Right Side */}
        <div className="">
          <div>
            <h3 className="font-semibold text-teal-700 text-xl">
              {props.client_heading}
            </h3>
            <p className="text-sm">{props.client_paragraph}</p>
          </div>
          <img src={props.image} alt="Case Study's Image" />
        </div>
      </div>
      {/* Summary, Engineers & Technologies */}
      <div className="mt-8 w-full">
        <h3 className="font-semibold text-dg-primary-900 text-xl">
          {props.summary_heading}
        </h3>
        <p className="text-sm">{props.summary_paragraph}</p>
      </div>
      <div className="mt-4 mb-8 w-full flex flex-col md:flex-row items-center justify-center md:justify-between">
        <p className="font-semibold text-dg-primary-900 text-sm">
          {props.engineers + '+ engineers'}
        </p>
        <ul className="flex flex-row items-center justify-center mt-2 md:mt-0">
          {study.technologies.data.map(technology => (
            <li key={technology.id} className="mr-2">
              <img src={technology.attributes.url} alt="Technology's Icon" width={27} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

CaseStudy.propTypes = {
  heading: propTypes.string,
  engineers: propTypes.number,
  image: propTypes.string,
  goals_heading: propTypes.string,
  goals_paragraph: propTypes.string,
  challange_heading: propTypes.string,
  challange_paragraph: propTypes.string,
  solution_heading: propTypes.string,
  solution_paragraph: propTypes.string,
  client_heading: propTypes.string,
  client_paragraph: propTypes.string,
  summary_heading: propTypes.string,
  summary_paragraph: propTypes.string,
  fetch: propTypes.string,
  key: propTypes.number,
};
