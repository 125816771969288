import React from 'react';
import { useNavigate } from 'react-router-dom';

const ActionCard_Array = ({ title, text, btn1, btn2, link1, link2 }) => {
  const linkTo = useNavigate();

  const handleLink = link => {
    linkTo(link);
  };

  //console.log(text);
  return (
    <div className="action-card px-64p py-72p flex flex-col justify-center items-center gap-48p">
      <h2 className="heading text-center">{title}</h2>
      {text &&
        text.length > 0 &&
        text.map((item, index) => (
          <p key={index} className="block paragraph">
            {item.field}
          </p>
        ))}
      <div className="flex flex-col-reverse md:flex-row gap-16p md:gap-72p justify-between w-fit">
        <button className="btn-secondary" onClick={() => handleLink(link2)}>
          {btn2}
        </button>
        <button className="btn-primary" onClick={() => handleLink(link1)}>
          {btn1}
        </button>
      </div>
    </div>
  );
};

export default ActionCard_Array;
