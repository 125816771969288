import React from 'react';

const TechNuggets = ({ data }) => {
    return (
      <div className="flex gap-2 mt-2 flex-wrap">
        {data && data.map((item, index) => (
          <div className="px-4 py-1 text-white text-p whitespace-nowrap font-medium bg-[#9FA2DD] rounded-lg" key={index}>{item.text ? item.text : ""}</div>
            ))}
      </div>
    );
};

export default TechNuggets;
